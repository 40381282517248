import TextField from "@mui/material/TextField";
import MuiAutocomplete, {
  createFilterOptions,
} from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FocusEventHandler,
  ForwardedRef,
  ReactElement,
  Ref,
  RefAttributes,
  forwardRef,
  useMemo,
  useState,
} from "react";
import { AxiosError, AxiosResponse } from "axios";
import { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { has, some } from "lodash";

export type FetchOptions<T> = <TError = AxiosError<unknown>>(options?: {
  query?: UseQueryOptions<
    Awaited<AxiosResponse<T[]>>,
    TError,
    Awaited<AxiosResponse<T[]>>,
    any
  >;
  // axios?: AxiosRequestConfig;
}) => UseQueryResult<Awaited<AxiosResponse<T[]>>, TError>;

type AutocompleteProps<T extends { id: number }> = {
  name?: string | undefined;
  value?: T | null | undefined;
  onChange?:
    | ((
        event: React.SyntheticEvent | null,
        value: T | null | undefined
      ) => void)
    | undefined;
  onBlur?: FocusEventHandler | undefined;
  getOptionLabel: (option: T) => string;
  fetchOptions: FetchOptions<T>;
  canCreateNewOption?: boolean;
  onNewOptionSelected?: (nome: string) => void;
  disabled?: boolean;
  placeholder?: string;
};

const filter = createFilterOptions<any>();

export const AsyncAutocomplete = forwardRef(
  <T extends { id: number }>(
    {
      name,
      value,
      onChange,
      onNewOptionSelected,
      onBlur,
      canCreateNewOption,
      fetchOptions,
      getOptionLabel,
      disabled,
      placeholder,
    }: AutocompleteProps<T>,
    ref?: ForwardedRef<any>
  ) => {
    const [open, setOpen] = useState(false);

    const { isFetching, data: response } = fetchOptions({
      query: { enabled: !!open },
    });
    const isLoading = open && isFetching;

    const options = (open && response ? response.data : null) || [];

    return (
      <InternalAutocomplete<T>
        forwardedRef={ref}
        onNewOptionSelected={onNewOptionSelected}
        onChange={onChange}
        open={open}
        setOpen={setOpen}
        getOptionLabel={getOptionLabel}
        canCreateNewOption={canCreateNewOption}
        options={options}
        isLoading={isLoading}
        value={value}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
      />
    );
  }
) as <T extends { id: number }>(
  props: AutocompleteProps<T> & RefAttributes<any>
) => ReactElement | null;

// **************
// IMPLEMENTATION
// **************

function InternalAutocomplete<T extends { id: number }>({
  forwardedRef,
  open,
  setOpen,
  isLoading,
  options: _options,
  name,
  value,
  onChange,
  onBlur,
  getOptionLabel,
  canCreateNewOption,
  onNewOptionSelected,
  disabled,
  placeholder,
}: {
  forwardedRef?: Ref<any>;
  open: boolean;
  setOpen: (open: boolean) => void;
  isLoading: boolean;
  options: T[];
} & Omit<AutocompleteProps<T>, "fetchOptions">) {
  const options = useMemo(() => {
    if (value) {
      const hasValue = some(_options, (x) => x.id === value.id);
      if (!hasValue) {
        return [value, ..._options];
      }
    }
    return _options;
  }, [_options, value]);

  const handleChange = (
    event: React.SyntheticEvent,
    value: T | null | string
  ) => {
    if (typeof value === "string" || (value && (value as any).inputValue)) {
      const nome =
        typeof value === "string"
          ? value
          : value && ((value as any).inputValue as string);

      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        onNewOptionSelected?.(nome);
      }, 1);
    } else {
      onChange?.(event, value);
    }
  };

  return (
    <MuiAutocomplete
      ref={forwardedRef}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option: T, value: T) => option.id === value.id}
      getOptionLabel={(option: T | string) => {
        // e.g. value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option && (option as any).__IS_NEW_OPTION) {
          return (option as any).title;
        }
        return getOptionLabel(option);
      }}
      freeSolo={canCreateNewOption}
      clearOnBlur
      options={options}
      loading={isLoading}
      value={value ?? null}
      onChange={handleChange}
      onBlur={onBlur}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    sx={{
                      position: "absolute",
                      right: 64,
                    }}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (!isLoading && canCreateNewOption && params.inputValue !== "") {
          filtered.push({
            __IS_NEW_OPTION: true,
            inputValue: params.inputValue,
            title: `Crea "${params.inputValue}"...`,
          } as any);
        }

        return filtered;
      }}
    />
  );
}
