/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  DispositivoDto,
  RegistroDto,
  UpdateRegistroResultDto,
  UpdateRegistroDto,
  DispositivoActionResultDto,
  DispositivoActionDto,
  ProprietaMisurataValueDto,
  GetDispositivoHistoryDataParams,
  TrasferimentoUpdateDtoInt32EntityChange,
} from ".././models";

export const getDispositivi = (
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DispositivoDto[]>> => {
  return axios.get(`/api/dispositivi`, options);
};

export const getGetDispositiviQueryKey = () => [`/api/dispositivi`] as const;

export const getGetDispositiviQueryOptions = <
  TData = Awaited<ReturnType<typeof getDispositivi>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDispositivi>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryOptions<
  Awaited<ReturnType<typeof getDispositivi>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDispositiviQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDispositivi>>> = ({
    signal,
  }) => getDispositivi({ signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetDispositiviQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDispositivi>>
>;
export type GetDispositiviQueryError = AxiosError<unknown>;

export const useGetDispositivi = <
  TData = Awaited<ReturnType<typeof getDispositivi>>,
  TError = AxiosError<unknown>
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<typeof getDispositivi>>,
    TError,
    TData
  >;
  axios?: AxiosRequestConfig;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDispositiviQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getDispositivo = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DispositivoDto>> => {
  return axios.get(`/api/dispositivi/${id}`, options);
};

export const getGetDispositivoQueryKey = (id: number) =>
  [`/api/dispositivi/${id}`] as const;

export const getGetDispositivoQueryOptions = <
  TData = Awaited<ReturnType<typeof getDispositivo>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispositivo>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getDispositivo>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetDispositivoQueryKey(id);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getDispositivo>>> = ({
    signal,
  }) => getDispositivo(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetDispositivoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDispositivo>>
>;
export type GetDispositivoQueryError = AxiosError<unknown>;

export const useGetDispositivo = <
  TData = Awaited<ReturnType<typeof getDispositivo>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispositivo>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDispositivoQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const getRegistriDispositivo = (
  id: number,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<RegistroDto[]>> => {
  return axios.get(`/api/dispositivi/${id}/registri`, options);
};

export const getGetRegistriDispositivoQueryKey = (id: number) =>
  [`/api/dispositivi/${id}/registri`] as const;

export const getGetRegistriDispositivoQueryOptions = <
  TData = Awaited<ReturnType<typeof getRegistriDispositivo>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRegistriDispositivo>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getRegistriDispositivo>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGetRegistriDispositivoQueryKey(id);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getRegistriDispositivo>>
  > = ({ signal }) => getRegistriDispositivo(id, { signal, ...axiosOptions });

  return { queryKey, queryFn, enabled: !!id, ...queryOptions };
};

export type GetRegistriDispositivoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getRegistriDispositivo>>
>;
export type GetRegistriDispositivoQueryError = AxiosError<unknown>;

export const useGetRegistriDispositivo = <
  TData = Awaited<ReturnType<typeof getRegistriDispositivo>>,
  TError = AxiosError<unknown>
>(
  id: number,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getRegistriDispositivo>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetRegistriDispositivoQueryOptions(id, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateRegistriDispositivo = (
  id: number,
  updateRegistroDto: UpdateRegistroDto[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<UpdateRegistroResultDto[]>> => {
  return axios.put(
    `/api/dispositivi/${id}/registri`,
    updateRegistroDto,
    options
  );
};

export const getUpdateRegistriDispositivoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRegistriDispositivo>>,
    TError,
    { id: number; data: UpdateRegistroDto[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateRegistriDispositivo>>,
  TError,
  { id: number; data: UpdateRegistroDto[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateRegistriDispositivo>>,
    { id: number; data: UpdateRegistroDto[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateRegistriDispositivo(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateRegistriDispositivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateRegistriDispositivo>>
>;
export type UpdateRegistriDispositivoMutationBody = UpdateRegistroDto[];
export type UpdateRegistriDispositivoMutationError = AxiosError<unknown>;

export const useUpdateRegistriDispositivo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRegistriDispositivo>>,
    TError,
    { id: number; data: UpdateRegistroDto[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getUpdateRegistriDispositivoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const actionDispositivo = (
  id: number,
  dispositivoActionDto: DispositivoActionDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<DispositivoActionResultDto>> => {
  return axios.put(
    `/api/dispositivi/${id}/dispositivoAction`,
    dispositivoActionDto,
    options
  );
};

export const getActionDispositivoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof actionDispositivo>>,
    TError,
    { id: number; data: DispositivoActionDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof actionDispositivo>>,
  TError,
  { id: number; data: DispositivoActionDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof actionDispositivo>>,
    { id: number; data: DispositivoActionDto }
  > = (props) => {
    const { id, data } = props ?? {};

    return actionDispositivo(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ActionDispositivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof actionDispositivo>>
>;
export type ActionDispositivoMutationBody = DispositivoActionDto;
export type ActionDispositivoMutationError = AxiosError<unknown>;

export const useActionDispositivo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof actionDispositivo>>,
    TError,
    { id: number; data: DispositivoActionDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getActionDispositivoMutationOptions(options);

  return useMutation(mutationOptions);
};
export const getDispositivoHistoryData = (
  id: number,
  idMisura: number,
  params?: GetDispositivoHistoryDataParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<ProprietaMisurataValueDto[]>> => {
  return axios.get(`/api/dispositivi/${id}/misura/${idMisura}/history`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetDispositivoHistoryDataQueryKey = (
  id: number,
  idMisura: number,
  params?: GetDispositivoHistoryDataParams
) =>
  [
    `/api/dispositivi/${id}/misura/${idMisura}/history`,
    ...(params ? [params] : []),
  ] as const;

export const getGetDispositivoHistoryDataQueryOptions = <
  TData = Awaited<ReturnType<typeof getDispositivoHistoryData>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idMisura: number,
  params?: GetDispositivoHistoryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispositivoHistoryData>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getDispositivoHistoryData>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getGetDispositivoHistoryDataQueryKey(id, idMisura, params);

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof getDispositivoHistoryData>>
  > = ({ signal }) =>
    getDispositivoHistoryData(id, idMisura, params, {
      signal,
      ...axiosOptions,
    });

  return { queryKey, queryFn, enabled: !!(id && idMisura), ...queryOptions };
};

export type GetDispositivoHistoryDataQueryResult = NonNullable<
  Awaited<ReturnType<typeof getDispositivoHistoryData>>
>;
export type GetDispositivoHistoryDataQueryError = AxiosError<unknown>;

export const useGetDispositivoHistoryData = <
  TData = Awaited<ReturnType<typeof getDispositivoHistoryData>>,
  TError = AxiosError<unknown>
>(
  id: number,
  idMisura: number,
  params?: GetDispositivoHistoryDataParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getDispositivoHistoryData>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetDispositivoHistoryDataQueryOptions(
    id,
    idMisura,
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const updateTrasferimentiDispositivo = (
  id: number,
  trasferimentoUpdateDtoInt32EntityChange: TrasferimentoUpdateDtoInt32EntityChange[],
  options?: AxiosRequestConfig
): Promise<AxiosResponse<unknown>> => {
  return axios.post(
    `/api/dispositivi/${id}/trasferimenti`,
    trasferimentoUpdateDtoInt32EntityChange,
    options
  );
};

export const getUpdateTrasferimentiDispositivoMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>,
    TError,
    { id: number; data: TrasferimentoUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>,
  TError,
  { id: number; data: TrasferimentoUpdateDtoInt32EntityChange[] },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>,
    { id: number; data: TrasferimentoUpdateDtoInt32EntityChange[] }
  > = (props) => {
    const { id, data } = props ?? {};

    return updateTrasferimentiDispositivo(id, data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateTrasferimentiDispositivoMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>
>;
export type UpdateTrasferimentiDispositivoMutationBody =
  TrasferimentoUpdateDtoInt32EntityChange[];
export type UpdateTrasferimentiDispositivoMutationError = AxiosError<unknown>;

export const useUpdateTrasferimentiDispositivo = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateTrasferimentiDispositivo>>,
    TError,
    { id: number; data: TrasferimentoUpdateDtoInt32EntityChange[] },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions =
    getUpdateTrasferimentiDispositivoMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useGetDispositivi = () =>
  //@ts-ignore
  useGetDispositivi(...([] as any[]));

export const __$$useGetDispositivo = () =>
  //@ts-ignore
  useGetDispositivo(...([] as any[]));

export const __$$useGetRegistriDispositivo = () =>
  //@ts-ignore
  useGetRegistriDispositivo(...([] as any[]));

export const __$$useUpdateRegistriDispositivo = () =>
  //@ts-ignore
  useUpdateRegistriDispositivo(...([] as any[]));

export const __$$useActionDispositivo = () =>
  //@ts-ignore
  useActionDispositivo(...([] as any[]));

export const __$$useGetDispositivoHistoryData = () =>
  //@ts-ignore
  useGetDispositivoHistoryData(...([] as any[]));

export const __$$useUpdateTrasferimentiDispositivo = () =>
  //@ts-ignore
  useUpdateTrasferimentiDispositivo(...([] as any[]));
