import { ProprietaMisurataDto, ProprietaMisurataValueDto } from "@/api";
import moment from "moment";
import numeral from "numeral";
import { _proprietaMisurataUtilsData } from "./proprietaMisurataUtilsData";

export type ProprietaMisurataType = ProprietaMisurataDto["type"];

export interface ProprietaMisurataTypeHelper {
  label: string;
  unit: string | null;
  unitPosition: "left" | "right" | false;
  format: (value: number) => string;
  getPercent: (value: number) => number;
}

const proprietaMisurataTypes: {
  [type in Exclude<
    ProprietaMisurataType,
    "unknown"
  >]: ProprietaMisurataTypeHelper;
} = {
  temperatura: generateHelper(_proprietaMisurataUtilsData.Temperatura),
  particelle: generateHelper(_proprietaMisurataUtilsData.Particelle),
  pressione: generateHelper(_proprietaMisurataUtilsData.Pressione),
  umidita: generateHelper(_proprietaMisurataUtilsData.Umidita),
  potenzaMotore: generateHelper(_proprietaMisurataUtilsData.PotenzaMotore),
  portata: generateHelper(_proprietaMisurataUtilsData.Portata),
} as const;

export function getProprietaMisurataHelper(
  type: ProprietaMisurataType
): ProprietaMisurataTypeHelper | null {
  if (type === "unknown") {
    return null;
  }
  const h = proprietaMisurataTypes[type];
  if (!h) {
    console.error("[ProprietaMisurata] Unknown type: " + type);
  }
  return h || null;
}

// -------------------------------------------------
// -------------------------------------------------
// -------------------------------------------------

function generateHelper(
  d: (typeof _proprietaMisurataUtilsData)[keyof typeof _proprietaMisurataUtilsData]
): ProprietaMisurataTypeHelper {
  const unit = d.unit;
  const unitPosition = (d.unitPosition ||
    false) as ProprietaMisurataTypeHelper["unitPosition"];
  return {
    label: d.label,
    unit: d.unit,
    unitPosition: unitPosition,
    format: (value) => {
      const valueString = numeral(value).format("0.0");
      if (d.unit) {
        return unitPosition === "left"
          ? `${unit} ${valueString}`
          : `${valueString} ${unit}`;
      }
      return valueString;
    },
    getPercent: makeGetPercent(d.percentMin, d.percentMax),
  };
}

function makeGetPercent(min: number, max: number) {
  const rangeDelta = max - min;
  const MIN = 5;
  const MAX = 100;
  return (value: number) => {
    const delta = value - min;
    let perc = (delta * 100) / rangeDelta;
    if (perc < MIN) {
      perc = MIN;
    } else if (perc > MAX) {
      perc = MAX;
    }
    return perc;
  };
}

export function isDanger(value: ProprietaMisurataValueDto | null) {
  return typeof value?.value === "number"
    ? (typeof value.dangerMin === "number" && value.value <= value.dangerMin) ||
        (typeof value.dangerMax === "number" && value.value >= value.dangerMax)
    : false;
}

export function isOld(value: ProprietaMisurataValueDto | null) {
  if (!value) {
    return true;
  }
  const maxDiff = 2 * 24 * 60 * 60 * 1000;
  const diff = moment().diff(moment(value.timestamp));
  return diff >= maxDiff;
}
