import Box from "@/elements/Box";
import Button from "@/elements/Button";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  Menu,
  MenuItem,
  MenuList,
} from "@mui/material";
import {
  AlertDto,
  DispositivoDto,
  ProprietaMisurataDto,
  getGetDispostivoAlertsQueryKey,
  useChangeDispositivoAlerts,
  useGetDispostivoAlerts,
} from "@/api";
import Loading, { SmallLoading } from "../Loading";
import LoadingError from "../LoadingError";
import { withOpeningReset } from "@/utils/withOpeningReset";
import Typography from "@/elements/Typography";
import PosizioneDispositivo from "../PosizioneDispositivo";
import { useAuth } from "@/context/useAuth";
import { useMemo, useState } from "react";
import {
  CellContext,
  ColumnDef,
  ColumnDefTemplate,
  createColumnHelper,
} from "@tanstack/react-table";
import {
  ProprietaMisurataTypeHelper,
  getProprietaMisurataHelper,
} from "@/utils/proprietaMisurataUtils";
import Table from "@/elements/Table";
import moment from "moment";
import { getMutedForeverDate, isMutedForever } from "@/utils/alert";
import { useDbRelationsUpdateForm } from "@/utils/useDbRelationsUpdate";
import { Controller } from "react-hook-form";
import Input from "@/elements/Input";
import NumberInput from "@/elements/Input/NumberInput";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const columnHelper = createColumnHelper<AlertDto>();

function ProprietaMisurataHistoryDialog({
  open = false,
  onClose,
  dispositivo,
  proprietaMisurate,
}: {
  open?: boolean;
  onClose?: () => void;
  dispositivo?: DispositivoDto;
  proprietaMisurate?: ProprietaMisurataDto[];
}) {
  const { data, error, isLoading } = useGetDispostivoAlerts(
    dispositivo?.id ?? 0,
    {
      query: {
        enabled: Boolean(open && dispositivo && dispositivo.id),
      },
    }
  );

  const _alerts = useMemo(() => {
    const alerts: AlertDto[] = [];
    const alertsByProp: { [id: number]: AlertDto } = {};

    data?.data?.forEach((p) => {
      if (p.proprietaMisurata?.id) {
        alertsByProp[p.proprietaMisurata.id] = p;
      }
    });

    proprietaMisurate?.forEach((p) => {
      const alert: AlertDto = alertsByProp[p.id] || {
        proprietaMisurata: p,
      };
      alerts.push(alert);
    });

    return alerts;
  }, [data?.data, proprietaMisurate]);

  const {
    data: alerts,
    control,
    resetField,
    watch,
    handleSubmit,
    getChangeSubmitData,
    create,
    remove,
    restore,
    getFieldName,
    getEntityState,
  } = useDbRelationsUpdateForm(_alerts);

  const { hasPermission } = useAuth();

  const columns = useMemo<ColumnDef<AlertDto, any>[]>(() => {
    const withProprietaMisurataHelper = <TValue,>(
      cell: (
        props: CellContext<AlertDto, TValue> & {
          helper: ProprietaMisurataTypeHelper | null | undefined;
        }
      ) => any
    ): ColumnDefTemplate<CellContext<AlertDto, TValue>> => {
      return (props) => {
        const proprietaMisurata = props.row.original.proprietaMisurata;
        const helper =
          proprietaMisurata &&
          proprietaMisurata.type &&
          getProprietaMisurataHelper(proprietaMisurata.type);

        return cell({ ...props, helper });
      };
    };

    const viewOrEditAlertValue = (key: keyof AlertDto) =>
      withProprietaMisurataHelper(({ helper, getValue, row }) => {
        if (helper) {
          if (hasPermission("DispositiviAlertValuesEdit")) {
            return (
              <Controller
                name={getFieldName(row.index, key)}
                control={control}
                render={({ field }) => {
                  let { value, name, onBlur, onChange, ref } = field;

                  if (key === "maxTimeOffline") {
                    const _onChange = onChange;
                    value =
                      typeof value === "number"
                        ? Math.round(value / 60)
                        : value;
                    onChange = (e) => {
                      _onChange(e.target.value * 60);
                    };
                  }
                  return (
                    <NumberInput
                      ref={ref}
                      name={name}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      startAdornment={
                        key !== "maxTimeOffline" &&
                        helper.unitPosition === "left" && (
                          <InputAdornment position="end">
                            {helper.unit}
                          </InputAdornment>
                        )
                      }
                      endAdornment={
                        key === "maxTimeOffline" ? (
                          <InputAdornment position="end">min</InputAdornment>
                        ) : (
                          helper.unitPosition === "right" && (
                            <InputAdornment position="end">
                              {helper.unit}
                            </InputAdornment>
                          )
                        )
                      }
                    />
                  );
                }}
              />
            );
          } else {
            const v = getValue();
            if (typeof v !== "number") {
              return null;
            }
            return helper.format(v);
          }
        }
        return null;
      });

    const columns = [
      columnHelper.accessor("proprietaMisurata", {
        header: "Misure",
        cell: withProprietaMisurataHelper(({ helper }) => {
          if (!helper) {
            return null;
          }
          const label = helper.label;
          return label;
        }),
      }),
      columnHelper.accessor("minValue", {
        header: "Valore minimo",
        cell: viewOrEditAlertValue("minValue"),
      }),
      columnHelper.accessor("maxValue", {
        header: "Valore massimo",
        cell: viewOrEditAlertValue("maxValue"),
      }),
      columnHelper.accessor("maxTimeOffline", {
        header: "Offline (minuti)",
        cell: viewOrEditAlertValue("maxTimeOffline"),
      }),
      hasPermission("DispositiviAlertInternalUserOptionsEdit") &&
        columnHelper.display({
          header: "Invio email - Tecnico",
          enableSorting: false,
          cell: ({ row }) => {
            return (
              <Controller
                name={getFieldName(row.index, "mutedUntilForAzienda")}
                control={control}
                render={({ field }) => {
                  let { value, onChange } = field;
                  return (
                    <NotificheAbilitateCell
                      mutedUntil={value}
                      onMutedUntilChange={onChange}
                    />
                  );
                }}
              />
            );
          },
        }),
      columnHelper.display({
        header: hasPermission("DispositiviAlertInternalUserOptionsEdit")
          ? "Invio email - Cliente"
          : "Invio email",
        enableSorting: false,
        cell: ({ row }) => {
          return (
            <Controller
              name={getFieldName(row.index, "mutedUntilForInternalUsers")}
              control={control}
              render={({ field }) => {
                let { value, onChange } = field;
                return (
                  <NotificheAbilitateCell
                    mutedUntil={value}
                    onMutedUntilChange={onChange}
                  />
                );
              }}
            />
          );
        },
      }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [control, getFieldName, hasPermission]);

  const handleClose = () => {
    onClose?.();
  };

  const { mutateAsync, isLoading: isSaving } = useChangeDispositivoAlerts({
    mutation: {
      onSuccess: () => {
        toast.success("Modifiche salvate!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const onSubmit = async () => {
    const _data = getChangeSubmitData();
    const data = _data
      .filter((x) => x.changeType === "updated")
      .map((x) => x.entity);
    console.log("change data:", data);
    try {
      await mutateAsync({
        dispositivoId: dispositivo!.id as number,
        data: data,
      });
      queryClient.invalidateQueries({
        queryKey: getGetDispostivoAlertsQueryKey(dispositivo!.id),
      });
      onClose?.();
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xl">
      <DialogTitle>
        Impostazioni Notifiche
        <Box>
          {dispositivo && (
            <Typography variant="caption" fontWeight="bold">
              {dispositivo.matricola}
            </Typography>
          )}

          {dispositivo && dispositivo.posizione && (
            <Box sx={{ minHeight: 18 }}>
              <PosizioneDispositivo posizione={dispositivo.posizione} />
            </Box>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          {isLoading && <Loading />}
          {!isLoading && error && <LoadingError error={error} />}
          {!isLoading && alerts && (
            <Table columns={columns} data={alerts} sortable={false} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {isSaving && <SmallLoading sx={{ mr: 1 }} />}

        <Button size="small" color="primary" onClick={handleSubmit(onSubmit)}>
          Salva
        </Button>

        {onClose && (
          <Button size="small" color="light" onClick={handleClose}>
            Chiudi
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(ProprietaMisurataHistoryDialog);

const disabilitaTimes = [
  {
    label: "2 ore",
    delta: 2 * 60 * 60,
  },
  {
    label: "8 ore",
    delta: 8 * 60 * 60,
  },
  {
    label: "24 ore",
    delta: 24 * 60 * 60,
  },
  {
    label: "7 giorni",
    delta: 7 * 24 * 60 * 60,
  },
  {
    label: "sempre",
    delta: Infinity,
  },
] as const;

function NotificheAbilitateCell({
  mutedUntil,
  onMutedUntilChange,
}: {
  mutedUntil: string | null | undefined;
  onMutedUntilChange?: (mutedUntil: string | null) => void;
}) {
  const enabled = !mutedUntil || moment(mutedUntil).isSameOrBefore(moment());

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (mutedUntil: string | null) => {
    onMutedUntilChange?.(mutedUntil);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        onClick={handleClick}
        sx={{ cursor: "pointer" }}
      >
        <Checkbox checked={enabled} />
        {!enabled && !!mutedUntil && (
          <Typography variant="caption">
            {isMutedForever(mutedUntil)
              ? `Disabilitato per sempre`
              : `Disabilitato fino al ${moment(mutedUntil).format(
                  "DD/MM/YYYY HH:mm"
                )}`}
          </Typography>
        )}
      </Box>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuList>
          <MenuItem selected={enabled} onClick={() => handleChange(null)}>
            Abilita
          </MenuItem>

          {disabilitaTimes.map((x, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => {
                  const d =
                    x.delta === Infinity
                      ? getMutedForeverDate()
                      : moment().add(x.delta, "s").toISOString();
                  handleChange(d);
                }}
              >
                Disabilita per {x.label}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
}
