import Box from "@/elements/Box";
import Button from "@/elements/Button";
import Typography from "@/elements/Typography";
import Card from "@mui/material/Card";
import PosizioneDispositivo from "../PosizioneDispositivo";
import ProprietaMisurataBar from "../ProprietaMisurataBar";
import { getDispositivoTypeLabel } from "@/utils/dispositivoUtils";
import { DispositivoDto, ProprietaMisurataDto } from "@/api";
import { Link } from "react-router-dom";

function DispositivoCard({
  dispositivo,
  proprietaMisurate,
  showPosizione,
}: {
  dispositivo: DispositivoDto;
  proprietaMisurate: ProprietaMisurataDto[];
  showPosizione: boolean;
}) {
  const typeLabel = getDispositivoTypeLabel(dispositivo.type);

  const { posizione } = dispositivo;

  return (
    <Card sx={{ minWidth: 300, maxWidth: 400 }}>
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" height="100%">
          <Typography variant="body2" color="text" fontWeight="medium">
            {typeLabel}
          </Typography>

          <Typography variant="h5" fontWeight="bold">
            {dispositivo.matricola}
          </Typography>

          {showPosizione && (
            <Box sx={{ minHeight: 18 }}>
              {posizione && <PosizioneDispositivo posizione={posizione} />}
            </Box>
          )}

          <Box mt={2} mb={0}>
            {proprietaMisurate.map((proprietaMisurata, index) => {
              return (
                <ProprietaMisurataBar
                  key={index}
                  proprietaMisurata={proprietaMisurata}
                  value={proprietaMisurata.lastValue ?? null}
                />
              );
            })}
          </Box>

          <Box mt="auto">
            <Button
              variant="textArrow"
              color="text"
              LinkComponent={Link}
              to={`/dispositivo/${dispositivo.id}`}
            >
              Vedi dettaglio
            </Button>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default DispositivoCard;
