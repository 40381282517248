import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import {
  AziendaDto,
  getGetAziendeQueryKey,
  useDeleteAzienda,
  useGetAziende,
} from "@/api";
import { createColumnHelper } from "@tanstack/react-table";
import Table from "@/elements/Table";
import { useAuth } from "@/context/useAuth";
import Button from "@/elements/Button";
import { Icon, Tooltip } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { useConfirmationDialog } from "@/utils/useConfirmationDialog";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import AziendaDialog from "../AziendaDialog";

const columnHelper = createColumnHelper<AziendaDto>();

function AziendePage() {
  const { hasPermission } = useAuth();
  const { isLoading, error, data } = useGetAziende();

  const [aziendaDialogState, setAziendaDialogState] = useState({
    open: false,
    azienda: null as AziendaDto | null,
  });

  const { askConfirmation, confirmationDialog } = useConfirmationDialog();

  const { mutateAsync } = useDeleteAzienda({
    mutation: {
      onSuccess: () => {
        toast.success("Azienda eliminata!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const queryClient = useQueryClient();

  const deleteAzienda = useCallback(
    async (aziendaId: number) => {
      try {
        await mutateAsync({ id: aziendaId });
        queryClient.invalidateQueries({ queryKey: getGetAziendeQueryKey() });
      } catch (err) {}
    },
    [mutateAsync, queryClient]
  );

  const columns = useMemo(() => {
    const canDelete = hasPermission("AziendaDelete");
    const canEdit = false; // hasPermission("AziendaEdit");

    const columns = [
      columnHelper.accessor("nome", {
        header: "Nome",
        size: undefined,
      }),
      (canDelete || canEdit) &&
        columnHelper.display({
          id: "buttons",
          header: "",
          size: canDelete && canEdit ? 250 : 125,
          maxSize: canDelete && canEdit ? 250 : 125,
          cell: ({ row }) => {
            return (
              <Box display="flex" gap={1}>
                {canEdit && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    startIcon={<Icon>edit</Icon>}
                    onClick={() => {
                      setAziendaDialogState({
                        open: true,
                        azienda: row.original,
                      });
                    }}
                  >
                    Modifica
                  </Button>
                )}
                {canDelete &&
                  (row.original.idElaboranet ? (
                    <Tooltip title="Per poter eliminare questa azienda devi prima eliminarla in Elaboranet">
                      <div>
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          startIcon={<Icon>delete_outlined</Icon>}
                          disabled
                        >
                          Elimina
                        </Button>
                      </div>
                    </Tooltip>
                  ) : (
                    <Button
                      variant="outlined"
                      size="small"
                      color="error"
                      startIcon={<Icon>delete_outlined</Icon>}
                      onClick={() => {
                        askConfirmation({
                          title: "Elimina azienda",
                          message: `Sei sicuro di voler eliminare l'azienda ${row.original.nome}?`,
                          okColor: "error",
                          onConfirm: () => {
                            return deleteAzienda(row.original.id!);
                          },
                        });
                      }}
                    >
                      Elimina
                    </Button>
                  ))}
              </Box>
            );
          },
        }),
    ].filter((x) => x);
    return columns as Exclude<(typeof columns)[number], boolean>[];
  }, [setAziendaDialogState, askConfirmation, deleteAzienda, hasPermission]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  const aziende = data.data || [];

  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Aziende
        </Typography>
        <Box display="flex" gap={1} pt={1} flexWrap="wrap">
          {/* {hasPermission("AziendaCreate") && (
            <Button
              size="small"
              color="light"
              onClick={() => {
                setAziendaDialogState({
                  open: true,
                  azienda: null,
                });
              }}
            >
              Aggiungi azienda
            </Button>
          )} */}
        </Box>
      </Box>

      <Table
        columns={columns}
        data={aziende}
        sortable={true}
        initialSort={[{ id: "nome", desc: false }]}
      />

      <AziendaDialog
        open={aziendaDialogState.open}
        azienda={aziendaDialogState.azienda}
        onClose={() => {
          setAziendaDialogState((x) => ({
            ...x,
            open: false,
          }));
        }}
      />
      {confirmationDialog}
    </Box>
  );
}

export default AziendePage;
