import Typography from "@/elements/Typography";
import Box from "@/elements/Box";
import Card from "@mui/material/Card";
import { CentraleDto, OlioDto } from "@/api";
import Button from "@/elements/Button";
import OilDropIcon from "@/assets/oil-drop-icon.png";
import numeral from "numeral";
import moment from "moment/moment";
import StoricoOlioDialog from "./StoricoOlioDialog";
import { useState } from "react";
import { useAuth } from "@/context/useAuth";

export default function CentraleOlioAttualeCard({
  olio,
  centrale,
}: {
  olio: OlioDto | null;
  centrale: CentraleDto;
}) {
  const { hasPermission } = useAuth();
  const [storicoOlioDialogOpen, setStoricoOlioDialogOpen] = useState(false);

  return (
    <Card
      sx={{
        minWidth: {
          xs: "100%",
          md: 300,
        },
        maxWidth: 500,
        flex: 1,
        alignSelf: "flex-start",
      }}
    >
      <Box p={2} flex={1}>
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" flexDirection="row" gap={1}>
            <img src={OilDropIcon} width={100} alt="Olio attuale" />

            <Box display="flex" flexDirection="column" gap={1}>
              <Typography
                variant="h5"
                color="text"
                fontWeight="medium"
                gutterBottom
              >
                Olio
              </Typography>

              {olio ? (
                <Typography variant="body2">
                  Modello:{" "}
                  <strong>
                    {olio.modelloOlio?.marca} - {olio.modelloOlio?.nome}
                  </strong>
                  <br />
                  Quantità:{" "}
                  <strong>{numeral(olio.quantitaLitri).format("0.0")} L</strong>
                  <br />
                  Sostituito il:{" "}
                  <strong>
                    {moment(olio.inseritoIl).format("DD/MM/YYYY")}
                  </strong>
                  <br />
                  Ultima bonifica:{" "}
                  {centrale.ultimaBonifica && (
                    <strong>
                      {moment(centrale.ultimaBonifica.data).format(
                        "DD/MM/YYYY"
                      )}
                    </strong>
                  )}
                  <br />
                </Typography>
              ) : (
                <Typography variant="body2">
                  <em>
                    Olio non mappato per questa centrale. Clicca sul pulsante
                    "Storico Olio" per inserire il modello e la quantità.
                  </em>
                </Typography>
              )}
            </Box>
          </Box>

          <Box display="flex" flexDirection="row-reverse" width="100%">
            <Button
              variant="contained"
              size="small"
              color="primary"
              onClick={() => {
                setStoricoOlioDialogOpen(true);
              }}
            >
              Storico olio
            </Button>
          </Box>
        </Box>
      </Box>

      <StoricoOlioDialog
        open={storicoOlioDialogOpen}
        centrale={centrale}
        storicoOlio={centrale.storicoOlio ?? []}
        onClose={() => {
          setStoricoOlioDialogOpen(false);
        }}
        canEdit={hasPermission("CentraleUpdateStoricoOlio")}
      />
    </Card>
  );
}
