// import { Link } from "react-router-dom";
import { UserLoginRequestDto, useLogin } from "@/api";
import cover from "@/assets/cover_image.jpg";
import { useAuth } from "@/context/useAuth";
import Box from "@/elements/Box";
import Button from "@/elements/Button";
import CoverLayout from "@/elements/CoverLayout";
import Input from "@/elements/Input";
import Typography from "@/elements/Typography";
import { FormHelperText } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { addServerErrorsFactory } from "../../utils/addServerErrorsFactory";

function Login() {
  const { user, onLogin } = useAuth();
  const navigate = useNavigate();
  const { isLoading, mutate: doLogin } = useLogin();

  //   const [rememberMe, setRememberMe] = useState(true);
  //   const handleSetRememberMe = () => setRememberMe(!rememberMe);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    register,
  } = useForm<UserLoginRequestDto>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<UserLoginRequestDto> = async (data) => {
    const result = await doLogin(
      { data },
      {
        onSuccess: (response) => {
          const user = response.data.user;
          onLogin(user);
        },
        onError: addServerErrorsFactory(setError),
      }
    );
  };

  return (
    <CoverLayout
      title="Gatti Filtrazioni Lubrificanti"
      description="Inserisci le tue credenziali per accedere alla piattaforma:"
      image={cover}
      showLogo
    >
      <Box
        component="form"
        role="form"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box mb={2}>
          <Input
            type="email"
            placeholder="Email"
            {...register("email")}
            disabled={isLoading}
            error={!!errors.email?.message}
          />
          {errors.email?.message ? (
            <FormHelperText error>{errors.email?.message}</FormHelperText>
          ) : null}
        </Box>
        <Box mb={2}>
          <Input
            type="password"
            placeholder="Password"
            {...register("password")}
            disabled={isLoading}
            error={!!errors.password?.message}
          />
          {errors.password?.message ? (
            <FormHelperText error>{errors.password?.message}</FormHelperText>
          ) : null}
        </Box>
        {/* <Box display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <Typography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </Typography>
        </Box> */}
        <Box mt={4} mb={1}>
          <Button
            color="secondary"
            fullWidth
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            Login
          </Button>
          {errors.root?.message ? (
            <FormHelperText error>{errors.root?.message}</FormHelperText>
          ) : null}
        </Box>
        <Box mt={3} textAlign="center">
          <Typography variant="button" color="secondary" fontWeight="regular">
            Password dimenticata?{" "}
            <Link to={"/recupero-password"}>
              <Typography variant="button" color="white" fontWeight="bold">
                Recupero password
              </Typography>
            </Link>
          </Typography>
        </Box>
      </Box>
    </CoverLayout>
  );
}

export default Login;
