import { RegistroDto, UpdateRegistroDto } from "@/api";
import {
  EditorRegistriGFLTFL700,
  formGFLTFL700ToRegistri,
  registriGFLTFL700ToForm,
} from "./GFLTFL700";

export const DispositivoMapper: {
  [key: string]: Mapper;
} = {
  GFLTFL700: {
    Component: EditorRegistriGFLTFL700,
    registriToForm: registriGFLTFL700ToForm,
    formToRegistri: formGFLTFL700ToRegistri,
  },
};

export type Mapper<Data = Record<string, any>> = {
  Component: any;
  registriToForm: (registri: RegistroDto[]) => Data;
  formToRegistri: (registri: Data) => UpdateRegistroDto[];
};
