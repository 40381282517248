import { useAuth } from "@/context/useAuth";
import { useMemo } from "react";

type RouteType =
  | ({
      key: any;
      type: "collapse";
      name: string;
      icon: string | JSX.Element;
      noCollapse: boolean;
    } & ({ href: string } | { route: string }))
  | {
      key: any;
      type: "title";
      title: string;
    }
  | {
      key: any;
      type: "divider";
    };

export function useSidebarRoutes() {
  const { hasPermission } = useAuth();

  return useMemo(() => {
    //////////
    //ICONS: https://fonts.google.com/icons
    let routes: RouteType[] = [
      {
        key: "title-dashboard-dispositivi",
        type: "title",
        title: "Centrali e Dispositivi",
      },
      hasPermission("DispositiviRead") && {
        key: "home",
        type: "collapse",
        icon: "sensors",
        name: "Macchinari/Sensori",
        route: "/dispositivi",
        noCollapse: true,
      },

      //TODO: manca il permesso CentraliRead analogo a DispositiviRead?
      // hasPermission("CentraliRead") &&
      {
        key: "home",
        type: "collapse",
        icon: "sensors",
        name: "Centrali",
        route: "/centrali",
        noCollapse: true,
      },
      {
        key: "div",
        type: "divider",
      },
      {
        key: "title-impostazioni",
        type: "title",
        title: "Impostazioni",
      },
      hasPermission("AziendeReadAll") && {
        key: "aziende",
        type: "collapse",
        icon: "factory",
        name: "Aziende",
        route: "/aziende",
        noCollapse: true,
      },
      hasPermission("UsersEsterniRead") && {
        key: "clienti",
        type: "collapse",
        icon: "group",
        name: "Account aziendali",
        route: "/account_aziende",
        noCollapse: true,
      },
      hasPermission("UsersInterniRead") && {
        key: "utenti",
        type: "collapse",
        icon: "manage_accounts",
        name: "Utenti",
        route: "/utenti",
        noCollapse: true,
      },
      hasPermission("UsersInterniRead") && {
        key: "ruoli_permessi",
        type: "collapse",
        icon: "lock_open_right",
        name: "Ruoli / Permessi",
        route: "/ruoli_permessi",
        noCollapse: true,
      },
      (hasPermission("ModelliFiltroChange") ||
        hasPermission("ModelliOlioChange") ||
        hasPermission("DescrizioniBonificaChange")) && {
        key: "configurazione",
        type: "collapse",
        icon: "settings",
        name: "Configurazione",
        route: "/configurazione",
        noCollapse: true,
      },
    ].filter((x) => x) as RouteType[];

    routes = routes.filter((x, index) => {
      // filter out title of empty sections
      return x.type === "title"
        ? index !== routes.length - 1 &&
            routes[index + 1].type !== "divider" &&
            routes[index + 1].type !== "title"
        : true;
    });

    // filter out divider of empty sections
    while (routes.length > 0 && routes[routes.length - 1].type === "divider") {
      routes.pop();
    }
    routes = routes.filter((x, index) => {
      // filter out divider of empty sections
      return x.type === "divider"
        ? index !== 0 && routes[index - 1].type !== "divider"
        : true;
    });

    return routes;
  }, [hasPermission]);
}
