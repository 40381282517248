import { DispositivoDto } from "@/api";

export function getDispositivoTypeLabel(type: DispositivoDto["type"]): string {
  switch (type) {
    case "unknown":
      return "Dispositivo non inizializzato";
    case "sensore":
      return "Sensore";
    case "macchinario":
      return "Macchinario";
  }
}
