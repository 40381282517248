import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  StabilimentoCreationDto,
  StabilimentoDto,
  useCreateStabilimenti,
} from "@/api";
import { useForm } from "react-hook-form";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import Loading, { SmallLoading } from "../Loading";
import { toast } from "react-toastify";

function StabilimentoCreateDialog({
  open = false,
  onClose,
  initialData,
  aziendaId,
}: {
  open?: boolean;
  onClose?: (stabilimento: StabilimentoDto | null) => void;
  initialData?: Partial<Omit<StabilimentoCreationDto, "aziendaId">> | null;
  aziendaId: number;
}) {
  const handleClose = () => {
    onClose?.(null);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<StabilimentoCreationDto>({
    defaultValues: {
      nome: initialData?.nome || "",
      aziendaId,
    },
  });

  const { isLoading, error, mutateAsync } = useCreateStabilimenti({
    mutation: {
      onSuccess: () => {
        toast.success("Stabilimento creato!");
      },
      onError: () => {
        toast.error("Errore nella richiesta");
      },
    },
  });

  const onSubmit = async (data: StabilimentoCreationDto) => {
    console.log(data);
    try {
      const result = await mutateAsync({
        data: data,
      });
      if (result.data) {
        onClose?.(result.data);
      }
    } catch (err) {}
  };

  console.log("error, ", error);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Crea stabilimento</DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-stabilimento-nome">
          Nome stabilimento
        </InputLabel>
        <TextField
          id="crea-stabilimento-nome"
          autoFocus
          {...register("nome", {
            required: "Inserisci il nome dell'stabilimento",
            minLength: 1,
          })}
          error={!!errors.nome?.message}
          helperText={errors.nome?.message || null}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Crea stabilimento
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(StabilimentoCreateDialog);
