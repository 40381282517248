import Typography from "@/elements/Typography";
import Box from "@/elements/Box";
import { useAuth } from "@/context/useAuth";
import ModelliFiltroTableCard from "@/components/ConfigurazionePage/ModelliFiltroTableCard";
import {
  useGetDescrizioniBonifica,
  useGetModelliFiltro,
  useGetModelliOlio,
} from "@/api";
import Loading from "@/components/Loading";
import LoadingError from "@/components/LoadingError";
import ModelliOlioTableCard from "@/components/ConfigurazionePage/ModelliOlioTableCard";
import DescrizioniBonificaTableCard from "@/components/ConfigurazionePage/DescrizioniBonificaTableCard";

export default function ConfigurazionePage() {
  const { hasPermission } = useAuth();

  const {
    isLoading: isLoadingModelliFiltro,
    data: modelliFiltro,
    error: errorModelliFiltro,
  } = useGetModelliFiltro();
  const {
    isLoading: isLoadingModelliOlio,
    data: modelliOlio,
    error: errorModelliOlio,
  } = useGetModelliOlio();
  const {
    isLoading: isLoadingDescrizioniBonifica,
    data: descrizioniBonifica,
    error: errorDescrizioniBonifica,
  } = useGetDescrizioniBonifica();

  const isLoading =
    isLoadingModelliFiltro ||
    isLoadingModelliOlio ||
    isLoadingDescrizioniBonifica;
  if (isLoading) {
    return <Loading />;
  }

  const error =
    errorModelliFiltro || errorModelliOlio || errorDescrizioniBonifica;
  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4" mr={2}>
          Configurazione
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap={2}>
        <ModelliFiltroTableCard
          modelliFiltro={modelliFiltro?.data ?? []}
          canEdit={hasPermission("ModelliFiltroChange")}
        />

        <ModelliOlioTableCard
          modelliOlio={modelliOlio?.data ?? []}
          canEdit={hasPermission("ModelliOlioChange")}
        />

        <DescrizioniBonificaTableCard
          descrizioniBonifiche={descrizioniBonifica?.data || []}
          canEdit={hasPermission("DescrizioniBonificaChange")}
        />
      </Box>
    </Box>
  );
}
