import { useGetCentrali } from "@/api/centrali/centrali";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import CentraleCard from "../CentraleCard";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import AziendaPicker from "../AziendaPicker";
import { useMemo, useState } from "react";
import { AziendaDto, CentraleDto, RepartoDto, StabilimentoDto } from "@/api";
import StabilimentoPicker from "../StabilimentoPicker";
import RepartoPicker from "../RepartoPicker";
import { useAuth } from "@/context/useAuth";
import Button from "@/elements/Button";
import CentraleCreateDialog from "@/components/CentraliPage/CentraleCreateDialog";
import { TablePagination } from "@mui/material";

type Filters = {
  azienda: AziendaDto | null;
  stabilimento: StabilimentoDto | null;
  reparto: RepartoDto | null;
};

function CentraliPage() {
  const { user, hasPermission } = useAuth();
  const { isLoading, error, data } = useGetCentrali();
  const [filters, setFilters] = useState<Filters>({
    azienda: null,
    stabilimento: null,
    reparto: null,
  });
  const [createCentraleDialogOpen, setCreateCentraleDialogOpen] =
    useState(false);

  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const changeFilter =
    (key: keyof typeof filters, resetKeys?: (keyof typeof filters)[]) =>
    (event: any, value: (typeof filters)[typeof key] | undefined) => {
      setFilters((f) => {
        const newFilters = {
          ...f,
          [key]: value || null,
        };
        resetKeys?.forEach((k) => {
          newFilters[k] = null;
        });
        return newFilters;
      });
    };

  const centrali = useMemo(() => {
    return (data?.data || []).filter((d) => {
      return matchFilter(d, filters);
    });
  }, [data?.data, filters]);

  const centraliCurrentPage = useMemo(() => {
    return centrali.slice(
      itemsPerPage * page,
      itemsPerPage * page + itemsPerPage
    );
  }, [centrali, page, itemsPerPage]);

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <LoadingError error={error} />;
  }

  return (
    <Box p={2}>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h4" mr={2}>
          Centrali
        </Typography>

        <Box display="flex" gap={1} pt={2} flexWrap="wrap">
          {!user?.isEsterno && (
            <AziendaPicker
              value={filters.azienda}
              onChange={changeFilter("azienda", ["stabilimento", "reparto"])}
              placeholder="Azienda"
            />
          )}
          <StabilimentoPicker
            aziendaId={
              user?.isEsterno ? user?.azienda?.id : filters.azienda?.id
            }
            value={filters.stabilimento}
            onChange={changeFilter("stabilimento", ["reparto"])}
            placeholder="Stabilimento"
          />
          <RepartoPicker
            stabilimentoId={filters.stabilimento?.id}
            value={filters.reparto}
            onChange={changeFilter("reparto")}
            placeholder="Reparto"
          />
        </Box>
      </Box>

      {/* {hasPermission("CentraleCreate") && (
        <Box display="flex" justifyContent="flex-end" mt={1}>
          <Box>
            <Button
              size="small"
              color="light"
              onClick={() => {
                setCreateCentraleDialogOpen(true);
              }}
            >
              Aggiungi centrale
            </Button>
          </Box>
        </Box>
      )} */}

      <Box
        mt={2}
        mb={0}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        gap={2}
      >
        {centraliCurrentPage.map((centrale) => {
          return (
            <CentraleCard
              key={centrale.id}
              centrale={centrale}
              showAzienda={!user?.isEsterno}
            />
          );
        })}
      </Box>

      {centrali.length === 0 && (
        <Box
          mt={2}
          mb={0}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          gap={2}
        >
          <Typography variant="body2">
            Nessun centrale
            {hasFilters(filters) ? " per i filtri impostati" : null}
          </Typography>
        </Box>
      )}

      <TablePagination
        component="div"
        page={page}
        count={centrali.length}
        onPageChange={(event, page) => {
          setPage(page);
        }}
        rowsPerPage={itemsPerPage}
        rowsPerPageOptions={[10, 20, 50]}
        onRowsPerPageChange={(e) => {
          setItemsPerPage(parseInt(e.target.value));
        }}
      />

      <CentraleCreateDialog
        azienda={filters.azienda ?? undefined}
        stabilimento={filters.stabilimento ?? undefined}
        reparto={filters.reparto ?? undefined}
        open={createCentraleDialogOpen}
        onClose={() => {
          setCreateCentraleDialogOpen(false);
        }}
      />
    </Box>
  );
}

export default CentraliPage;

function matchFilter(c: CentraleDto, filters: Filters): unknown {
  if (filters.azienda && c?.azienda?.id !== filters.azienda.id) {
    return false;
  }
  if (filters.stabilimento && c.stabilimento?.id !== filters.stabilimento.id) {
    return false;
  }
  if (filters.reparto && c.reparto?.id !== filters.reparto.id) {
    return false;
  }
  return true;
}

function hasFilters(filters: Filters): boolean {
  return Boolean(filters.azienda || filters.stabilimento || filters.reparto);
}
