import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  CentraleCreationDto,
  CentraleDto,
  CentraleUpdateDto,
  getGetCentraleQueryKey,
  useCreateCentrali,
  useUpdateCentrale,
} from "@/api";
import { Controller, useForm, UseFormSetError } from "react-hook-form";
import { FormHelperText, InputLabel } from "@mui/material";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { addServerErrorsFactory } from "@/utils/addServerErrorsFactory";
import { useQueryClient } from "@tanstack/react-query";
import UserPicker from "@/components/UserPicker";
import { useAuth } from "@/context/useAuth";

function CentraleCreateDialog({
  open = false,
  onClose,
  centrale,
  initialData,
}: {
  open?: boolean;
  onClose?: (centrale: CentraleDto | null) => void;
  centrale?: CentraleDto;
  initialData?: CentraleCreationDto | null;
}) {
  const { hasPermission } = useAuth();
  const handleClose = () => {
    onClose?.(null);
  };

  const {
    control,
    setValue,
    register,
    handleSubmit,
    formState: { errors, isValid },
    setError,
  } = useForm<CentraleDto>({
    defaultValues: centrale || {
      nome: initialData?.nome || "",
      repartoId: initialData?.repartoId,
    },
  });

  const { saveAsync, isLoading, error } = useMutator(centrale, setError);

  const onSubmit = async (data: CentraleCreationDto) => {
    try {
      const result = await saveAsync(data);
      if (result.data) {
        onClose?.(result.data);
      }
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {centrale?.id ? "Modifica centrale " : "Crea centrale"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-centrale-nome">Nome centrale</InputLabel>
        <TextField
          id="crea-centrale-nome"
          autoFocus
          {...register("nome", {
            required: "Inserisci il nome dell'centrale",
            minLength: 1,
          })}
          error={!!errors.nome?.message}
          helperText={errors.nome?.message || null}
          fullWidth
          disabled
        />

        {hasPermission("UsersInterniRead") && (
          <>
            <InputLabel htmlFor="crea-centrale-referente-commerciale">
              Referente commerciale
            </InputLabel>
            <Controller
              control={control}
              name="referenteCommerciale"
              render={({ field: { value, onChange } }) => {
                return (
                  <UserPicker
                    value={value}
                    onChange={(_, v) => {
                      onChange(v);
                      setValue("referenteCommercialeId", v?.id);
                    }}
                    role="Commerciale"
                  />
                );
              }}
            />
            {errors.referenteCommerciale && (
              <FormHelperText error>
                {errors.referenteCommerciale?.message}
              </FormHelperText>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {centrale?.id ? "Salva centrale" : "Crea centrale"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(CentraleCreateDialog);

function useMutator(
  centrale: Partial<CentraleDto> | null | undefined,
  setError: UseFormSetError<Partial<CentraleDto>>
): {
  isLoading: boolean;
  error: AxiosError<unknown, any> | null;
  saveAsync: (data: Partial<CentraleDto>) => Promise<any>;
} {
  const isNew = !centrale?.id;
  const queryClient = useQueryClient();

  const create = useCreateCentrali({
    mutation: {
      onSuccess: () => {
        toast.success("Centrale creata!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const update = useUpdateCentrale({
    mutation: {
      onSuccess: () => {
        toast.success("Centrale salvata!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const { isLoading, error } = isNew ? create : update;

  return {
    isLoading,
    error,
    saveAsync: async (data: Partial<CentraleDto>) => {
      const result = isNew
        ? await create.mutateAsync({ data: data as CentraleCreationDto })
        : await update.mutateAsync({
            id: centrale.id!,
            data: data as CentraleUpdateDto,
          });

      if (!isNew) {
        await queryClient.invalidateQueries(
          getGetCentraleQueryKey(centrale.id!)
        );
      }
      return result;
    },
  };
}
