import { FocusEventHandler, forwardRef, useState } from "react";
import { StabilimentoDto, useGetStabilimenti } from "@/api";
import StabilimentoCreateDialog from "@/components/StabilimentoCreateDialog";
import { AsyncAutocomplete, FetchOptions } from "../../elements/Autocomplete";

const StabilimentoPicker = forwardRef(
  (
    {
      aziendaId,
      value,
      onChange,
      onBlur,
      name,
      canCreateStabilimento = false,
      placeholder,
    }: {
      aziendaId?: number;
      value?: StabilimentoDto | null | undefined;
      onChange?: (
        event: React.SyntheticEvent | null,
        value: StabilimentoDto | null | undefined
      ) => void;
      onBlur?: FocusEventHandler;
      name?: string;
      canCreateStabilimento?: boolean;
      placeholder?: string;
    },
    ref
  ) => {
    const disabled = !aziendaId;

    const [
      createStabilimentoDialogOptions,
      setCreateStabilimentoDialogOptions,
    ] = useState<{ open: boolean; nome?: string }>({ open: false, nome: "" });

    const onCreateStabilimentoClose = (
      stabilimento: StabilimentoDto | null
    ) => {
      onChange?.(null, stabilimento);
      setCreateStabilimentoDialogOptions({ open: false });
    };

    function onNewOptionSelected(nome: string) {
      setCreateStabilimentoDialogOptions({ open: true, nome: nome });
    }

    const _useGetStabilimenti: FetchOptions<StabilimentoDto> = (options) =>
      useGetStabilimenti({ aziendaId }, options);

    return (
      <>
        <AsyncAutocomplete<StabilimentoDto>
          ref={ref}
          onNewOptionSelected={onNewOptionSelected}
          onChange={onChange}
          fetchOptions={_useGetStabilimenti}
          getOptionLabel={(s) => s.nome}
          canCreateNewOption={canCreateStabilimento}
          value={value}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
        />
        {canCreateStabilimento && aziendaId && (
          <StabilimentoCreateDialog
            aziendaId={aziendaId}
            open={createStabilimentoDialogOptions.open}
            onClose={onCreateStabilimentoClose}
            initialData={{ nome: createStabilimentoDialogOptions.nome }}
          />
        )}
      </>
    );
  }
);

export default StabilimentoPicker;
