// *******************
// *******************
// FILE AUTO GENERATO! n// *******************
// *******************

export const _permissionsGroupMap = {
  DispositiviRead: "Dispositivi",
  DispositiviReadAll: "Dispositivi",
  DispositiviUpdateTrasferimenti: "Dispositivi",
  DispositivoRegistriRead: "Dispositivi - Registri",
  DispositivoRegistriWrite: "Dispositivi - Registri",
  DispositiviDatiStoriciRead: "Dispositivi - Dati",
  DispositiviDatiStoriciReadAll: "Dispositivi - Dati",
  DispositiviAlertValuesEdit: "Dispositivi - Alerts",
  DispositiviAlertInternalUserOptionsEdit: "Dispositivi - Alerts",
  AziendeReadAll: "Aziende",
  AziendaCreate: "Aziende",
  AziendaEdit: "Aziende",
  AziendaDelete: "Aziende",
  StabilimentiReadAll: "Stabilimenti",
  StabilimentoCreate: "Stabilimenti",
  RepartiReadAll: "Reparti",
  RepartoCreate: "Reparti",
  CentraliRead: "Centrali",
  CentraliReadAll: "Centrali",
  CentraleCreate: "Centrali",
  CentraleUpdate: "Centrali",
  CentraleUpdateAlloggiamentiFiltro: "Centrali",
  CentraleUpdateStoricoFiltri: "Centrali",
  CentraleUpdateStoricoOlio: "Centrali",
  CentraleUpdateReminders: "Centrali",
  CentraleUpdateBonifica: "Centrali",
  CentraleUpdateFiltrazioni: "Centrali",
  CentraleDelete: "Centrali",
  TrasferimentiChange: "Trasferimenti",
  UsersInterniRead: "Users",
  UsersEsterniRead: "Users",
  UsersInterniChange: "Users",
  UsersEsterniChange: "Users",
  ModelliFiltroChange: "Dizionari",
  ModelliOlioChange: "Dizionari",
  DescrizioniBonificaChange: "Dizionari",
  FileUpload: "File",
  AccessAll: "SuperAdmin",
} as const;
