import { useGetUsers, UserInternoDto } from "@/api";
import { Role } from "@/utils/rolesAndPermissions";
import { AsyncAutocomplete, FetchOptions } from "@/elements/Autocomplete";
import React, { FocusEventHandler, forwardRef } from "react";

const UserPicker = forwardRef(
  (
    {
      value,
      onChange,
      role,
      onBlur,
      name,
      placeholder,
      disabled,
    }: {
      value: UserInternoDto | null | undefined;
      onChange: (
        event: React.SyntheticEvent | null,
        u: UserInternoDto | null | undefined
      ) => void;
      role: Role;
      onBlur?: FocusEventHandler;
      name?: string;
      placeholder?: string;
      disabled?: boolean;
    },
    ref
  ) => {
    const _useGetUsers: FetchOptions<UserInternoDto> = (options) =>
      useGetUsers({ role }, options);

    return (
      <AsyncAutocomplete<UserInternoDto>
        ref={ref}
        onChange={onChange}
        fetchOptions={_useGetUsers}
        getOptionLabel={(u) => `${u.name} ${u.lastName}`}
        value={value}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
      />
    );
  }
);

export default UserPicker;
