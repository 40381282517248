import { CentraleDto } from "@/api";
import Box from "@/elements/Box";
import Typography from "@/elements/Typography";
import { Icon } from "@mui/material";
import { Fragment } from "react";

function PosizioneCentrale({
  centrale,
  showAzienda,
}: {
  centrale: CentraleDto;
  showAzienda: boolean;
}) {
  const parts = [
    showAzienda ? centrale.azienda?.nome : undefined,
    centrale.stabilimento?.nome,
    centrale.reparto?.nome,
  ].filter((x) => x) as string[];

  return (
    <Box display="flex" flexDirection="column">
      <Typography
        variant="caption"
        color="text"
        fontWeight="regular"
        sx={{ display: "inline-flex", flexWrap: "wrap" }}
      >
        {parts.map((part, index) => {
          return (
            <Fragment key={index}>
              {index > 0 && <Separator />}
              {part}
            </Fragment>
          );
        })}
      </Typography>
    </Box>
  );
}

export default PosizioneCentrale;

function Separator() {
  return <Icon sx={{ mx: 0.5 }}>arrow_forward</Icon>;
}
