// *******************
// *******************
// FILE AUTO GENERATO! n// *******************
// *******************

export const _proprietaMisurataUtilsData = {
  Temperatura: {
    label: "Temperatura",
    labelWithArticle: "la temperatura",
    unit: "°C",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 80.0,
  },
  Particelle: {
    label: "Numero di particelle",
    labelWithArticle: "il numero di particelle",
    unit: null,
    unitPosition: null,
    percentMin: 0.0,
    percentMax: 100000.0,
  },
  Pressione: {
    label: "Pressione",
    labelWithArticle: "la pressione",
    unit: "bar",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 100.0,
  },
  Umidita: {
    label: "Umidità",
    labelWithArticle: "l'umidità",
    unit: "%",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 100000.0,
  },
  Portata: {
    label: "Portata",
    labelWithArticle: "la portata",
    unit: "l/h",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 100000.0,
  },
  PotenzaMotore: {
    label: "Potenza motore",
    labelWithArticle: "la potenza motore",
    unit: "%",
    unitPosition: "right",
    percentMin: 0.0,
    percentMax: 100000.0,
  },
} as const;
