/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Gatti Filtrazioni
 * OpenAPI spec version: v1
 */
import axios from "axios";
import type { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  StabilimentoDto,
  GetStabilimentiParams,
  StabilimentoCreationDto,
} from ".././models";

export const getStabilimenti = (
  params?: GetStabilimentiParams,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StabilimentoDto[]>> => {
  return axios.get(`/api/stabilimenti`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};

export const getGetStabilimentiQueryKey = (params?: GetStabilimentiParams) =>
  [`/api/stabilimenti`, ...(params ? [params] : [])] as const;

export const getGetStabilimentiQueryOptions = <
  TData = Awaited<ReturnType<typeof getStabilimenti>>,
  TError = AxiosError<unknown>
>(
  params?: GetStabilimentiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStabilimenti>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryOptions<
  Awaited<ReturnType<typeof getStabilimenti>>,
  TError,
  TData
> & { queryKey: QueryKey } => {
  const { query: queryOptions, axios: axiosOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetStabilimentiQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getStabilimenti>>> = ({
    signal,
  }) => getStabilimenti(params, { signal, ...axiosOptions });

  return { queryKey, queryFn, ...queryOptions };
};

export type GetStabilimentiQueryResult = NonNullable<
  Awaited<ReturnType<typeof getStabilimenti>>
>;
export type GetStabilimentiQueryError = AxiosError<unknown>;

export const useGetStabilimenti = <
  TData = Awaited<ReturnType<typeof getStabilimenti>>,
  TError = AxiosError<unknown>
>(
  params?: GetStabilimentiParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<typeof getStabilimenti>>,
      TError,
      TData
    >;
    axios?: AxiosRequestConfig;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetStabilimentiQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const createStabilimenti = (
  stabilimentoCreationDto: StabilimentoCreationDto,
  options?: AxiosRequestConfig
): Promise<AxiosResponse<StabilimentoDto>> => {
  return axios.post(`/api/stabilimenti`, stabilimentoCreationDto, options);
};

export const getCreateStabilimentiMutationOptions = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStabilimenti>>,
    TError,
    { data: StabilimentoCreationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}): UseMutationOptions<
  Awaited<ReturnType<typeof createStabilimenti>>,
  TError,
  { data: StabilimentoCreationDto },
  TContext
> => {
  const { mutation: mutationOptions, axios: axiosOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof createStabilimenti>>,
    { data: StabilimentoCreationDto }
  > = (props) => {
    const { data } = props ?? {};

    return createStabilimenti(data, axiosOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type CreateStabilimentiMutationResult = NonNullable<
  Awaited<ReturnType<typeof createStabilimenti>>
>;
export type CreateStabilimentiMutationBody = StabilimentoCreationDto;
export type CreateStabilimentiMutationError = AxiosError<unknown>;

export const useCreateStabilimenti = <
  TError = AxiosError<unknown>,
  TContext = unknown
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof createStabilimenti>>,
    TError,
    { data: StabilimentoCreationDto },
    TContext
  >;
  axios?: AxiosRequestConfig;
}) => {
  const mutationOptions = getCreateStabilimentiMutationOptions(options);

  return useMutation(mutationOptions);
};

export const __$$useGetStabilimenti = () =>
  //@ts-ignore
  useGetStabilimenti(...([] as any[]));

export const __$$useCreateStabilimenti = () =>
  //@ts-ignore
  useCreateStabilimenti(...([] as any[]));
