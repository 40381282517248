import { FocusEventHandler, forwardRef, useState } from "react";
import { RepartoDto, useGetReparti } from "@/api";
import RepartoCreateDialog from "@/components/RepartoCreateDialog";
import { AsyncAutocomplete, FetchOptions } from "../../elements/Autocomplete";

const RepartoPicker = forwardRef(
  (
    {
      stabilimentoId,
      value,
      onChange,
      onBlur,
      name,
      canCreateReparto = false,
      placeholder,
    }: {
      stabilimentoId?: number;
      value?: RepartoDto | null | undefined;
      onChange?: (
        event: React.SyntheticEvent | null,
        value: RepartoDto | null | undefined
      ) => void;
      onBlur?: FocusEventHandler;
      name?: string;
      canCreateReparto?: boolean;
      placeholder?: string;
    },
    ref
  ) => {
    const disabled = !stabilimentoId;

    const [createRepartoDialogOptions, setCreateRepartoDialogOptions] =
      useState<{ open: boolean; nome?: string }>({ open: false, nome: "" });

    const onCreateRepartoClose = (reparto: RepartoDto | null) => {
      onChange?.(null, reparto);
      setCreateRepartoDialogOptions({ open: false });
    };

    function onNewOptionSelected(nome: string) {
      setCreateRepartoDialogOptions({ open: true, nome: nome });
    }

    const _useGetReparti: FetchOptions<RepartoDto> = (options) =>
      useGetReparti({ stabilimentoId }, options);

    return (
      <>
        <AsyncAutocomplete<RepartoDto>
          ref={ref}
          onNewOptionSelected={onNewOptionSelected}
          onChange={onChange}
          fetchOptions={_useGetReparti}
          getOptionLabel={(s) => s.nome}
          canCreateNewOption={canCreateReparto}
          value={value}
          onBlur={onBlur}
          name={name}
          disabled={disabled}
          placeholder={placeholder}
        />
        {canCreateReparto && stabilimentoId && (
          <RepartoCreateDialog
            stabilimentoId={stabilimentoId}
            open={createRepartoDialogOptions.open}
            onClose={onCreateRepartoClose}
            initialData={{ nome: createRepartoDialogOptions.nome }}
          />
        )}
      </>
    );
  }
);

export default RepartoPicker;
