import { UserDto, UserLoginResponseDto, currentUser } from "@/api";
import {
  Permission,
  Role,
  rolesHavePermission,
} from "@/utils/rolesAndPermissions";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export interface AuthContextType {
  user: UserDto | null;
  isLoading: boolean;
  onLogin: (user: UserDto) => void;
  onUserUpdated: (user: UserDto) => void;
  onLogout: () => void;
  hasPermission: (permission: Permission) => boolean;
}

export const AuthContext = createContext<AuthContextType>({
  user: null,
  isLoading: false,
  onLogin: () => {},
  onUserUpdated: () => {},
  onLogout: () => {},
  hasPermission: () => false,
});

export function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = useState<UserDto | null>(null);
  let [isLoading, setLoading] = useState<boolean>(true);

  const queryClient = useQueryClient();

  // load initial state
  useEffect(() => {
    let unmounted = false;
    let start = new Date();
    const load = async () => {
      let result: AxiosResponse<UserLoginResponseDto, any> | null = null;
      try {
        result = await currentUser();
      } catch (err) {
        console.log(err);
      }

      if (unmounted) {
        return;
      }

      const MIN_LOADING_TIME = 300;

      const diff = new Date().getTime() - start.getTime();
      const timeout = diff < MIN_LOADING_TIME ? MIN_LOADING_TIME - diff : 1;

      setTimeout(() => {
        if (unmounted) {
          return;
        }

        const user = result?.data?.user;
        if (user) {
          setUser(user);
        }
        setLoading(false);
      }, timeout);
    };

    load();

    return () => {
      unmounted = true;
    };
  }, []);

  let onLogin = useCallback(
    (newUser: UserDto) => {
      setUser(newUser);
    },
    [setUser]
  );

  let onLogout = useCallback(() => {
    queryClient.invalidateQueries();
    queryClient.clear();
    setUser(null);
  }, [setUser, queryClient]);

  const userRoles = user?.roles as Role[];
  let hasPermission = useCallback(
    (permission: Permission) => {
      return rolesHavePermission(userRoles, permission);
    },
    [userRoles]
  );

  let value = {
    user,
    isLoading,
    onLogin,
    onUserUpdated: onLogin,
    onLogout,
    hasPermission,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
