import Button from "@/elements/Button";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import {
  UserInternoDto,
  UserInternoCreateDto,
  UserInternoUpdateDto,
  getGetClientiQueryKey,
  getGetUsersQueryKey,
  useCreateUser,
  useUpdateUser,
} from "@/api";
import { UseFormSetError, useForm } from "react-hook-form";
import { withOpeningReset } from "../../utils/withOpeningReset";
import { SmallLoading } from "../Loading";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";
import { addServerErrorsFactory } from "@/utils/addServerErrorsFactory";
import { ROLE_CLIENTE, roles } from "@/utils/rolesAndPermissions";
import { AxiosError } from "axios";

function UtenteDialog({
  open = false,
  onClose,
  user,
}: {
  open?: boolean;
  onClose?: () => void;
  user?: UserInternoDto | null;
}) {
  const handleClose = () => {
    onClose?.();
  };

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isValid },
  } = useForm<UserInternoDto>({
    defaultValues: user || {},
  });

  const { saveAsync, isLoading, error } = useMutator(user, setError);

  const queryClient = useQueryClient();

  const onSubmit = async (data: UserInternoDto) => {
    console.log("data:", data);
    try {
      await saveAsync(data);
      queryClient.invalidateQueries({ queryKey: getGetUsersQueryKey() });
      queryClient.invalidateQueries({ queryKey: getGetClientiQueryKey() });
      onClose?.();
    } catch (err) {}
  };

  console.log("error, ", error);

  const selectedRoles = watch("roles") || [];

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        {user ? "Modifica utente " : "Creazione utente"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="user-nome">Nome</InputLabel>
        <TextField
          id="user-nome"
          autoFocus
          {...register("name", {
            required: "Campo obbligatorio",
            minLength: 1,
          })}
          error={!!errors.name?.message}
          helperText={errors.name?.message || null}
          fullWidth
        />

        <InputLabel htmlFor="user-cognome">Cognome</InputLabel>
        <TextField
          id="user-cognome"
          autoFocus
          {...register("lastName", {
            required: "Campo obbligatorio",
            minLength: 1,
          })}
          error={!!errors.lastName?.message}
          helperText={errors.lastName?.message || null}
          fullWidth
        />

        <InputLabel htmlFor="user-email">Email</InputLabel>
        <TextField
          id="user-email"
          autoFocus
          {...register("email", {
            required: "Campo obbligatorio",
            minLength: 1,
          })}
          error={!!errors.email?.message}
          helperText={errors.email?.message || null}
          fullWidth
        />

        <InputLabel>Ruolo</InputLabel>
        <FormGroup sx={{ ml: 2 }}>
          {roles.map((role) => {
            if (role === ROLE_CLIENTE) {
              return null;
            }
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedRoles.indexOf(role) !== -1}
                    onChange={(e) => {
                      const selected = e.target.checked;
                      let newRoles: string[] = roles.filter((x) => {
                        return x === role
                          ? selected
                          : selectedRoles.indexOf(x) !== -1;
                      });
                      setValue("roles", newRoles);
                    }}
                  />
                }
                label={role}
              />
            );
          })}
          {errors.roles && (
            <FormHelperText error>{errors.roles?.message}</FormHelperText>
          )}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {user ? "Salva utente" : "Crea utente"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(UtenteDialog);

function useMutator(
  user: Partial<UserInternoDto> | null | undefined,
  setError: UseFormSetError<Partial<UserInternoDto>>
): {
  isLoading: boolean;
  error: AxiosError<unknown, any> | null;
  saveAsync: (data: UserInternoDto) => Promise<any>;
} {
  const isNew = !user?.id;

  const create = useCreateUser({
    mutation: {
      onSuccess: () => {
        toast.success("Utente creato!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const update = useUpdateUser({
    mutation: {
      onSuccess: () => {
        toast.success("Utente salvato!");
      },
      onError: addServerErrorsFactory(setError, () => {
        toast.error("Errore nella richiesta");
      }),
    },
  });

  const { isLoading, error } = isNew ? create : update;

  return {
    isLoading,
    error,
    saveAsync: async (data: UserInternoDto) => {
      return isNew
        ? create.mutateAsync({ data: data as UserInternoCreateDto })
        : update.mutateAsync({
            id: user.id!,
            data: data as UserInternoUpdateDto,
          });
    },
  };
}
