import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@/elements/Button";
import { withOpeningReset } from "@/utils/withOpeningReset";
import {
  BonificaDto,
  CentraleDto,
  getGetCentraleQueryKey,
  useUpdateBonifiche,
} from "@/api";
import { Controller, useForm } from "react-hook-form";
import { FormHelperText, InputLabel } from "@mui/material";
import { SmallLoading } from "../Loading";
import { useQueryClient } from "@tanstack/react-query";
import { DateTimeField } from "@mui/x-date-pickers";
import moment from "moment/moment";
import DescrizioneBonificaPicker from "@/components/DescrizioneBonificaPicker";
import FileField from "@/components/FileField";

function BonificaDialog({
  open = false,
  onClose,
  centrale,
  bonifica,
}: {
  open?: boolean;
  onClose?: () => void;
  centrale: CentraleDto;
  bonifica?: Partial<BonificaDto> | null;
}) {
  const handleClose = () => {
    onClose?.();
  };

  const {
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    resetField,
    control,
  } = useForm<BonificaDto>({
    defaultValues: bonifica || {},
  });

  const { mutateAsync, isLoading } = useUpdateBonifiche();

  const queryClient = useQueryClient();

  const onSubmit = async (data: BonificaDto) => {
    try {
      await mutateAsync({
        id: centrale.id,
        data: [
          {
            id: bonifica?.id,
            changeType: bonifica?.id ? "updated" : "added",
            entity: data,
          },
        ],
      });
      await queryClient.invalidateQueries({
        queryKey: getGetCentraleQueryKey(centrale.id),
      });
      onClose?.();
    } catch (err) {}
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {bonifica?.id ? "Modifica bonifica" : "Crea bonifica"}
      </DialogTitle>
      <DialogContent>
        <InputLabel htmlFor="crea-bonifica-data">Data</InputLabel>
        <Controller
          name="data"
          control={control}
          rules={{ required: "Specifica la data" }}
          render={({ field, fieldState: { error } }) => {
            const { value, name, onBlur, onChange, ref } = field;
            const m =
              typeof value === "string"
                ? moment(value)
                : moment.isMoment(value)
                ? value
                : null;
            return (
              <>
                <DateTimeField
                  ref={ref}
                  format="DD/MM/YYYY"
                  name={name}
                  onBlur={onBlur}
                  onChange={(value) => onChange(value?.format("YYYY-MM-DD"))}
                  value={m}
                  fullWidth
                />
                {error && (
                  <FormHelperText error>{error?.message}</FormHelperText>
                )}
              </>
            );
          }}
        />

        <InputLabel htmlFor="crea-bonifica-scadenza">Scadenza</InputLabel>
        <Controller
          name="scadenza"
          control={control}
          render={({ field, fieldState: { error } }) => {
            const { value, name, onBlur, onChange, ref } = field;
            const m =
              typeof value === "string"
                ? moment(value)
                : moment.isMoment(value)
                ? value
                : null;
            return (
              <>
                <DateTimeField
                  ref={ref}
                  format="DD/MM/YYYY"
                  name={name}
                  onBlur={onBlur}
                  onChange={(value) =>
                    onChange(value?.startOf("day")?.format("YYYY-MM-DD"))
                  }
                  value={m}
                  fullWidth
                />
                {error && (
                  <FormHelperText error>{error?.message}</FormHelperText>
                )}
              </>
            );
          }}
        />

        <InputLabel htmlFor="crea-bonifica-descrizione">Descrizione</InputLabel>
        <Controller
          name="descrizione"
          control={control}
          rules={{ required: "Seleziona una descrizione" }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <DescrizioneBonificaPicker
                value={value}
                onChange={(e, v) => onChange(v)}
              />
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </>
          )}
        />

        <InputLabel htmlFor="crea-bonifica-rapportino">Rapportino</InputLabel>
        <Controller
          name="fileRapportino"
          control={control}
          rules={{ required: "Seleziona un file" }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <>
              <FileField
                value={value}
                onChange={(f) => {
                  onChange(f);
                  if (f?.id) {
                    setValue("fileRapportinoId", f?.id);
                  } else {
                    resetField("fileRapportinoId");
                  }
                }}
              />
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        {isLoading && <SmallLoading sx={{ mr: 1 }} />}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={!isValid || isLoading}
          onClick={handleSubmit(onSubmit)}
        >
          {bonifica?.id ? "Salva bonifica" : "Crea bonifica"}
        </Button>
        <Button
          variant="contained"
          color="light"
          disabled={isLoading}
          onClick={handleClose}
        >
          Annulla
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default withOpeningReset(BonificaDialog);
